.industry-new-section {
    padding: 120px 0;
    background: #EAF5FD;    
    min-height: 360px;

    .industry-new-content {
        padding-bottom: 40px;
        h2 {
            font-size: 40px;
            line-height: 150%;
            font-weight: 700;
            color: #040405;
            text-align: center;
            margin: 0;
        }
    }
    .industry-tab-heading {
        margin-bottom: 40px;
        justify-content: center;
        padding-right: 0;

        .nav-item {
            .nav-link {
                background-color: #fff;
                padding: 10px 20px;
                color: #555C60;
                font-size: 16px;
                line-height: 150%;
                font-weight: 500;
                position: relative;
                transition: 0.3s;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 4px;
                border-radius: 8px;
                border: 2px solid rgba(0, 134, 230, 0.20);

                &.active,
                &:hover {
                    background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%) !important;
                    color: #fff !important;
                    border-color: #EAF5FD !important;
                }
            }
        }
    }
    .indutry-tab-content {
        .industry-right-content {
            border: 1px solid rgba(0, 134, 230, 0.20);
            padding: 32px;
            background: #fff;
            border-radius: 16px;
            position: relative;
            margin-bottom: 32px;

            .industry-lt-content {
                height: 100%;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: space-between;
                min-height: 120px;
                h4 {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #555C60;
                    transition: 0.3s;
                }
                .view-more-btn {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 600;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #555C60;
                    text-transform: uppercase;
                    white-space: nowrap;
                    box-shadow: none !important;
                    border: none;

                    img {
                        opacity: 0;
                        width: 14px;
                        margin-left: 0;
                        transition: 0.3s;
                    }
                    &:hover {
                        img {
                            opacity: 1;
                            margin-left: 8px;
                        }
                    }
                }
            }

            .industry-ct-content {
                p {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    margin: 0;
                    color: #555C60;
                    transition: 0.3s;

                    span {
                        color: #555C60 !important;
                    }
                }
            }
            .industry-rt-img {
                position: absolute;
                right: 0;
                width: 290px;
                height: 220px;
                border-radius: 16px;
                overflow: hidden;
                top: 0;
                bottom: 0;
                margin: auto;
                opacity: 0;
                transition: 0.6s;
                img {
                    width: 100%;
                    height: 100%;
                }
                &:before {
                    content: "";
                    background: linear-gradient(
                        122.09deg,
                        #0091da -11.62%,
                        rgba(0, 145, 218, 0.88) 8.53%,
                        rgba(69, 168, 104, 0) 69.21%,
                        #84bd00 100%
                    );
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            &:hover,
            &.active {
                .industry-lt-content {
                    h4 {
                        color: #040405;
                    }
                    .view-more-btn {
                        background: var(--step-bg-light);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                .industry-ct-content {
                    p {
                        color: #040405;

                        span {
                            color: #040405 !important;
                        }
                    }
                }
                .industry-rt-img {
                    opacity: 1;
                }
            }
        }
    }
}

.industry-md-block {
    display: none;
}

@media only screen and (max-width: 1400px) and (min-width: 1112px) {
    .container {
        max-width: 1000px !important;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content h4 {
        font-size: 16px;
        line-height: 22px;
    }
    .industry-new-section .industry-tab-heading .nav-item .nav-link {
        padding: 8px 6px;
        font-size: 14px;
    }
    .industry-new-section .indutry-tab-content .indutry-content .industry-solution-list li {
        padding-bottom: 26px;
    }
    .industry-new-section {
        padding: 60px 0;
    }
    .industry-new-section .industry-new-content {
        padding-bottom: 22px;
    }

    .industry-new-section .indutry-tab-content .indutry-content h2 {
        font-size: 24px;
        margin-bottom: 32px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content {
        padding: 24px;
        margin-bottom: 24px;
    }
    .industry-new-section .indutry-tab-content .tab-pane:last-child .industry-right-content {
        margin-bottom: 0;
    }

    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content h4 {
        font-size: 16px;
        line-height: 22px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content .view-more-btn {
        font-size: 12px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-ct-content p {
        font-size: 14px;
    }

    .industry-new-section .indutry-tab-content .industry-right-content .industry-rt-img {
        width: 225px;
        height: 105%;
    }
}

@media (max-width: 1024px) {
    .industry-new-section .industry-tab-heading .nav-item .nav-link {
        padding: 10px 9px;
    }
    .industry-new-section .indutry-tab-content .indutry-content .industry-solution-list li {
        padding-bottom: 26px;
    }
    .industry-new-section {
        padding: 60px 0;
    }
    .industry-new-section .industry-new-content {
        padding-bottom: 22px;
    }
    .industry-new-section .industry-new-content h2 {
        font-size: 32px;
    }
    .industry-new-section .indutry-tab-content .indutry-content h2 {
        font-size: 24px;
        margin-bottom: 32px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content {
        padding: 24px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content h4 {
        font-size: 16px;
        line-height: 22px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content .view-more-btn {
        font-size: 12px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-ct-content p {
        font-size: 14px;
    }
    .industry-new-section .industry-tab-heading .nav-item .nav-link {
        font-size: 14px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-rt-img {
        width: 225px;
        height: 105%;
    }
}

@media (max-width: 992px) {
    .industry-new-section {
        padding: 40px 0;
    }

    .industry-new-section .industry-new-content h2 {
        font-size: 24px;
    }
    .industry-new-section .industry-new-content {
        padding-bottom: 20px;
    }
    .industry-new-section .industry-tab-heading {
        margin-bottom: 24px;
    }
    .industry-new-section .indutry-tab-content .indutry-content h2 {
        font-size: 22px;
        margin-bottom: 20px;
    }
    .industry-new-section .indutry-tab-content .indutry-content .industry-solution-list li a {
        font-size: 14px;
        padding-left: 26px;
    }
    .industry-new-section .indutry-tab-content .indutry-content .industry-solution-list li {
        padding-bottom: 16px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-solution-img {
        margin-bottom: 14px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content p {
        font-size: 14px;
        margin-bottom: 16px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content {
        padding: 16px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content h4 {
        font-size: 13px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-ct-content p {
        font-size: 12px;
    }

    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content {
        min-height: unset;
        flex-direction: row !important;
    }

    // .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content a {
    //     display: none !important;
    // }
}

@media (max-width: 767px) {
    .industry-md-block {
        display: block;
    }
    .industry-md-none {
        display: none;
    }
    .industry-new-section {
        padding: 40px 0;
    }
    .industry-new-section .industry-tab-heading {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .industry-new-section .industry-tab-heading .nav-item {
        width: 100%;
        max-width: 25%;
    }

    .industry-new-section .industry-tab-heading .nav-item .nav-link {
        padding: 10px 5px;
        font-size: 13px;
        text-align: left;
    }
    .industry-sm-dropdown {
        margin-bottom: 16px;
        .dropdown-toggle {
            text-align: left;
            border: 2px solid rgba(0, 134, 230, 0.20);
            border-radius: 12px;
            background-color: #fff;
            overflow: hidden;
            padding: 14px;
            color: #040405;
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            position: relative;
            box-shadow: none !important;
            width: 100%;
            &::after {
                content: "";
                background-image: url(../../../public/images/home/drop-down-grey.svg);
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                right: 14px;
                border: none;
                position: absolute;
                top: 10px;
            }
            &:active {
                background-color: #fff;
                border-color: rgba(0, 134, 230, 0.20);
                color: #040405;
            }
            span{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 90%;
                display: block;
            }
        }
        .dropdown-menu {
            transition: unset;
            border: 2px solid rgba(0, 134, 230, 0.20);
            border-radius: 16px;
            padding: 0;
            .dropdown-item {
                font-size: 14px;
                border-bottom: 2px solid rgba(0, 134, 230, 0.20);
                padding: 16px 12px;
                font-weight: 500;
                color: #555C60;
                &:last-child {
                    border: none;
                }
                &:hover {
                    background-color: rgba(0, 134, 230, 0.20);
                }
            }
        }
    }

    .industry-new-section .indutry-tab-content .industry-right-content {
        padding: 32px 40px;
        text-align: center;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-rt-img {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content {
        display: unset;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-lt-content h4 {
        font-size: 16px;
        margin-bottom: 16px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-ct-content p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        margin-bottom: 24px;
    }
    .industry-new-section .indutry-tab-content .industry-right-content .industry-ct-content .btn-primary {
        min-width: 108px;
    }
    .industry-new-section .indutry-tab-content .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
    }
    .industry-new-section .indutry-tab-content .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background-color: #0086E6;
        border-radius: 100px;
    }
    .industry-new-section .indutry-tab-content .swiper-pagination-bullet-active {
        width: 8px;
        height: 8px;
        background: linear-gradient(283.13deg, #0086E6 5.56%, #8DD0FF 113.66%);
    }
}


@media (min-width: 768px) {
    .industry-new-section .indutry-tab-content .industry-right-content  .industry-rt-img {
        min-width: 222px !important;
        min-height: 160px !important;
        max-width: 222px !important;
        max-height: 160px !important;
    }
}
@media (min-width: 992px) {
    .industry-new-section .indutry-tab-content .industry-right-content  .industry-rt-img {
        min-width: 290px !important;
        min-height: 220px !important;
        max-width: 290px !important;
        max-height: 220px !important;
    }
}

/* General styles for btn-read-more */
.btn-read-more {
    padding: 8px 16px;
    font-size: 14px;
    color: #007bff;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Hover effect */
.btn-read-more:hover {
    color: #0056b3;
    text-decoration: underline;
}

/* For small devices (max-width: 767px) */
@media (max-width: 767px) {
    .btn-read-more {
        margin-left: 0;
        margin-top: 8px;
        text-align: center;
        width: 100%;
    }
}

/* For tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .btn-read-more {
        margin-left: -10px;
        margin-top: 10px;
        display: inline-block;
        text-align: center;
    }
}

/* For medium devices (520px to 834px) */
@media (min-width: 520px) and (max-width: 834px) {
    .btn-read-more {
        margin-left: -15px !important;
        display: inline-block;
        text-align: center;
    }
}

/* For larger tablets and small desktops (835px to 968px) */
@media (min-width: 835px) and (max-width: 968px) {
    .btn-read-more {
        margin-left: -16px !important;
        display: inline-block;
        text-align: center;
    }
}

/* For larger screens (above 1024px) */
@media (min-width: 1025px) {
    .btn-read-more {
        margin-left: -15px;
        margin-top: 10px;
        text-align: left;
    }
}
@media (min-width: 1025px) and (max-width: 1500px) {
    .btn-read-more {
        margin-left: -16px !important;
        display: inline-block;
        text-align: center;
    }
}


@media (max-width: 480px) {
    .industry-new-section .indutry-tab-content .industry-right-content {
        padding: 20px 20px;
    }
}